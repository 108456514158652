/* eslint-disable react/jsx-key */
import React, { lazy } from 'react'
import AuthorizedRoute from '../components/AuthorizedRoute'
import UnauthorizedRoute from '../components/UnauthorizedRoute'

// const MyAccount = lazy(() => import('../pages/MyAccount/MyAccount')) 
// const Dashboard = lazy(() => import('../pages/Dashboard'))
// const Printer = lazy(() => import('../pages/Printer'))
const SignIn = lazy(() => import('../pages/SignIn/SignIn'))
const SignUp = lazy(() => import('../pages/SignUp/SignUp'))
// const PasswordReset = lazy(() => import('../pages/PasswordReset/PasswordReset'))
const About = lazy(() => import('../pages/About'))
// const Home = lazy(() => import('../pages/Home/Home'))
const Overview = lazy(() => import('../pages/TankTelemetry/Overview'))
const Summary = lazy(() => import('../pages/TankTelemetry/Summary'))
const EventLog = lazy(() => import('../pages/TankTelemetry/EventLog'))
// const DigitalBilling = lazy(() => import('../pages/Tanker/DigitalBilling'))
// const Maintenance = lazy(() => import('../pages/Tanker/Maintenance'))
// const TankerLog = lazy(() => import('../pages/Tanker/TankerLog'))
// const Application = lazy(() => import('../pages/Application/Application'))
const Customer = lazy(() => import('../pages/Customer'))
const User = lazy(() => import('../pages/User'))
const Model = lazy(() => import('../pages/Model'))
const Site = lazy(() => import('../pages/Site'))
const Trends = lazy(() => import('../pages/Trends'))
/* const PageNotFound = lazy(() => import('../pages/PageNotFound/PageNotFound')) */
const Login = lazy(() => import('../pages/SignIn/Login'))
const LoginCallback = lazy(() => import('../pages/SignIn/LoginCallback'))

const routes = [
  {
    path: '/authentication/login',
    exact: true,
    element: (
      <UnauthorizedRoute>
        <Login redirectTo="/" />
      </UnauthorizedRoute>
    ),
  },
  {
    path: '/authentication/login-callback',
    exact: true,
    element: (
      <UnauthorizedRoute>
        <LoginCallback redirectTo="/" />
      </UnauthorizedRoute>
    ),
  },
  {
    path: '/signin',
    exact: true,
    element: (
      <UnauthorizedRoute>
        <SignIn redirectTo="/overview" />
      </UnauthorizedRoute>
    ),
  },
  {
    path: '/signup',
    exact: true,
    element: (
      <UnauthorizedRoute>
        <SignUp redirectTo="/overview" />
      </UnauthorizedRoute>
    ),
  },
/*   {
    path: '/password_reset',
    exact: true,
    element: (
      <UnauthorizedRoute>
        <PasswordReset />
      </UnauthorizedRoute>
    ),
  }, */
  {
    path: '/about',
    exact: true,
    element: <About />,
  },
/*   {
    path: '/PageNotFound',
    exact: true,
    element: <PageNotFound />,
  }, */
  /* {
    path: '/my_account',
    exact: true,
    element: (
      <AuthorizedRoute>
        <MyAccount />
      </AuthorizedRoute>
    ),
  }, */
/*   {
    path: '/home',
    exact: true,
    element: (
      <AuthorizedRoute>
        <Home />
      </AuthorizedRoute>
    ),
  }, */
  {
    path: '/overview',
    exact: true,
    element: (
      <AuthorizedRoute>
        <Overview />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/summary',
    exact: true,
    element: (
      <AuthorizedRoute>
        <Summary />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/eventlog',
    exact: true,
    element: (
      <AuthorizedRoute>
        <EventLog />
      </AuthorizedRoute>
    ),
  },
/*   {
    path: '/digitalbilling',
    exact: true,
    element: (
      <AuthorizedRoute>
        <DigitalBilling />
      </AuthorizedRoute>
    ),
  }, */
/*   {
    path: '/maintenance',
    exact: true,
    element: (
      <AuthorizedRoute>
        <Maintenance />
      </AuthorizedRoute>
    ),
  }, */
/*   {
    path: '/tankerlog',
    exact: true,
    element: (
      <AuthorizedRoute>
        <TankerLog />
      </AuthorizedRoute>
    ),
  }, */
/*   {
    path: '/application',
    exact: true,
    element: (
      <AuthorizedRoute>
        <Application />
      </AuthorizedRoute>
    ),
  }, */
  {
    path: '/customer',
    exact: true,
    element: (
      <AuthorizedRoute>
        <Customer />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/user',
    exact: true,
    element: (
      <AuthorizedRoute>
        <User />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/trends',
    exact: true,
    element: (
      <AuthorizedRoute>
        <Trends />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/model',
    exact: true,
    element: (
      <AuthorizedRoute>
        <Model />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/site',
    exact: true,
    element: (
      <AuthorizedRoute>
        <Site />
      </AuthorizedRoute>
    ),
  },
]

export default routes
